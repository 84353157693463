import { Link, useNavigate } from "react-router-dom";
import Seo from "../../../utils/Seo";

const Developer = () => {
    const navigate = useNavigate();

    return (
        <>
            <Seo dynamic={false} />
            <section id="content">
                <main>
                    <h1 className="title">Bot</h1>
                    <ul className="breadcrumbs">
                        <li><Link to={"/dashboard"}>Home</Link></li>
                        <li className="divider">/</li>
                        <li><a href="#" className="active">Bot</a></li>
                    </ul>
                    <div className="data">
                        <div className="data">
                            <div className="content-data">
                                <div className="cards-container-feature">
                                    <div className="card card-two" onClick={() => { navigate("/dashboard/bot/manage") }}>
                                        <h3>Your Bots</h3>
                                        <div className="desc">
                                            Manage your bots effortlessly: edit their settings or delete them whenever needed. Transform any bot into a join4join bot and start earning coins automatically!
                                        </div>
                                        <div className="footer_card"></div>
                                    </div>
                                    <div className="card card-two" onClick={() => { navigate("/dashboard/bot/add") }}>
                                        <h3>Create Your Bot</h3>
                                        <div className="desc">
                                            Create your own Discord bot and transform it into a join4join bot to earn coins automatically!
                                            Enjoy 5 bonus coins every day your bot stays online, plus additional earnings through our affiliate system.
                                            It's the easiest way to grow your coin balance effortlessly!
                                        </div>
                                        <div className="footer_card"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </section>
        </>
    );
};

export default Developer;