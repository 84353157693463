import { Link, useNavigate } from "react-router-dom";
import Seo from "../../../utils/Seo";
import addBot from "../../../api/methods/addBot";
import { useState } from "react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const Add_Bot = () => {
    const [bot_id, setBotID] = useState(undefined);
    const [bot_token, setBotTOKEN] = useState(undefined);

    const navigate = useNavigate()

    const add_Bot_ = async () => {
        if (bot_id && bot_token) {
            const request = await addBot(bot_id.trim(), bot_token.trim());
            if (request && request.success === true) {
                navigate("/dashboard/bot/manage")
            } else {
                if (request && request.success === false && request.message) {
                    MySwal.fire({
                        title: 'Error',
                        html: <>
                            <p>{request.message}</p>
                        </>,
                        footer: '<a href="https://t.me/join4joindiscord">Support Server</a>',
                        timer: 3000,
                        icon: "error"
                    })
                }
            }
        } else {
            MySwal.fire({
                title: 'Params Missing',
                html: <>
                    <p>You need to provide the bot ID and the bot token. Try again...</p>
                </>,
                footer: '<a href="https://t.me/join4joindiscord">Support Server</a>',
                timer: 3000,
                icon: "error"
            })
        }
    }

    return (
        <>
            <Seo dynamic={false} />
            <section id="content">
                <main>
                    <h1 className="title">Create a Bot</h1>
                    <ul className="breadcrumbs">
                        <li><Link to={"/dashboard/bot"}>Bot</Link></li>
                        <li className="divider">/</li>
                        <li><a href="#" className="active">Create a Bot</a></li>
                    </ul>
                    <div className="data">
                        <div className="content-data">
                            <div className="head.all">
                                <h3 className="title">Create a Bot</h3>
                                <div className="db-container background">
                                    <div className="form">
                                        <div class="info-msg">
                                            <div className="position_info_msg">
                                                <div>
                                                    <div><i class="fa fa-info-circle"></i></div><div>Create your own join4join bot.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="info-msg">
                                            <div className="position_info_msg">
                                                <div>
                                                    <div><i class="fa fa-info-circle"></i></div><div>Earn 5 free coins daily by keeping your bot online.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="error-msg">
                                            <div className="position_info_msg">
                                                <div>
                                                    <div><i class="fa fa-warning"></i></div><div>Only one bot can be added per user.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <h3>Bot ID:</h3>
                                        <div className="send_premium_form">
                                            <input onChange={(event) => setBotID(event.target.value)} type="text" placeholder="Example: 1129027979209613413" />
                                        </div>
                                        <h3>Bot TOKEN:</h3>
                                        <div className="send_premium_form">
                                            <input onChange={(event) => setBotTOKEN(event.target.value)} type="text" placeholder="Example: MTExMTI1MTMxNTA1NDYxMjUsMA.GJB-MV.kBFzn7JW6obdkYwmsK7vkJ8Ut1wbeh1Aem6IsU" />
                                        </div>
                                        <button onClick={() => { add_Bot_() }}>Send</button>
                                        <div class="info-msg">
                                            <div className="position_info_msg">
                                                <div>
                                                    <div><i class="fa fa-info-circle"></i></div><div>Once added the bot should be online within 5 minutes.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </section>
        </>
    );
};

export default Add_Bot;